import { getModules } from "./lib/helpers.js"
import "/src/css/index.css"
import initSpeedbumpLinks from "./modules/speedbump.ts"

export async function initJsModules(moduleEls = getModules()) {
	moduleEls.forEach(async (el) => {
		if (!el.dataset || !el.dataset.jsModule) {
			return
		}

		switch (el.dataset.jsModule.toLowerCase()) {
			case "header":
				let header = await import("./modules/header.ts")
				header.default.init(el)
				break
			case "announcement":
				let announcement = await import("./modules/announcement.ts")
				announcement.default.init(el)
				break
			case "home-hero":
				let homeHero = await import("./modules/homeHero.ts")
				homeHero.default.init(el)
				break
			case "rellax":
				let rellax = await import("./modules/rellax.ts")
				rellax.default.init(el)
				break
			case "internal-page-hero":
				let internalPageHero = await import(
					"./modules/internalPageHero.ts"
				)
				internalPageHero.default.init(el)
				break
			case "in-view": {
				let inView = await import("./modules/inView.ts")
				inView.default.init(el)
				break
			}
			case "video": {
				let video = await import("./modules/video.ts")
				video.default.init(el)
				break
			}
			case "glide": {
				let glide = await import("./modules/glide.ts")
				glide.default.init(el)
				break
			}
			case "channel-listing": {
				let channelListing = await import(
					"./modules/channelListing.ts"
				)
				channelListing.default.init(el)
				break
			}
			case "horizontal-scroll": {
				let horizontalScroll = await import(
					"./modules/horizontal-scroll.ts"
				)
				horizontalScroll.default.init(el)
				break
			}
			default:
				console.log(`Module ${el.dataset.jsModule} not found.`)
				break
		}
	})
}

initJsModules()
initSpeedbumpLinks()
