import { getSpeedbumpLinks } from "../lib/helpers.js"

const SpeedbumpModal = document.querySelector(
	".js-speedbump-modal"
) as HTMLElement

const SpeedbumpClose = Array.from(
	document.querySelectorAll(".js-speedbump-close")
)

const SpeedbumpContinue = document.querySelector(".js-speedbump-continue")

const closeSpeedbump = () => {
	SpeedbumpModal.classList.remove("active")
	document.body.style.removeProperty("overflow-y")
	SpeedbumpClose.forEach((btn) =>
		btn.removeEventListener("click", closeSpeedbump)
	)
	SpeedbumpModal.removeEventListener("click", clickBackground)
}

const clickBackground = (event) => {
	event.target === SpeedbumpModal && closeSpeedbump()
}

const initSpeedbump = (el: HTMLElement) => {
	const href = el.getAttribute("href")
	const target = el.getAttribute("target") || "_self"

	if (!href) return

	el.addEventListener("click", (event) => {
		event.preventDefault()
		SpeedbumpModal.classList.add("active")
		document.body.style.setProperty("overflow-y", "hidden")

		SpeedbumpContinue?.setAttribute("href", href)
		SpeedbumpContinue?.setAttribute("target", target)

		if (target === "_blank") {
			SpeedbumpContinue?.addEventListener("click", closeSpeedbump)
		}

		SpeedbumpClose.forEach((btn) => {
			btn.addEventListener("click", closeSpeedbump)
		})

		SpeedbumpModal.addEventListener("click", clickBackground)
	})
}

export default async function initSpeedbumpLinks(
	moduleLinks = getSpeedbumpLinks()
) {
	moduleLinks.forEach(async (el) => {
		if (
			el.classList.contains("js-speedbump-exclude") ||
			el.href.includes(window.location.hostname)
		) {
			return
		}

		initSpeedbump(el)
	})
}
